import {
    Button,
    ButtonGroup,
    CheckBox,
    Control,
    Icon,
    Label,
    NumberBox,
    Pagination,
    Panel,
    Select,
    TextArea,
    TextBox,
} from "@core/components/alt-ui/controls";
import {
    IGood,
    IGoodInventory,
    IGoodInventoryCreateDto,
    IGoodInventoryItem,
    IGoodInventoryItemReportResult,
    IGoodInventoryUpdateDto,
    ISelectedData,
    IStore,
    Locale,
} from "@lib";
import { Table, TableColumn, TableFooter } from "@core/components/alt-ui/table";
import { DelayedOperation } from "@/utils/delayed-operation";
import { Uuid } from "@/utils/uuid";
import { AltMessage } from "@/utils/plugins/alt-message";
import { Modal } from "@core/components/alt-ui/modal";
import router from "@/router/router";
import { Formatter } from "@/utils/formatter";

export interface IGoodInventoryModalContext {
    userId: string;
    store?: IStore;
    message: AltMessage;

    /** Инвентаризация для редактирования. */
    inventory: IGoodInventory;

    /** Обработчик создания инвентаризации. */
    createHandler?: (dto: IGoodInventoryCreateDto) => Promise<IGoodInventory | null>;

    /** Обработчик изменения инвентаризации. */
    updateHandler?: (orig: IGoodInventory, dto: IGoodInventoryUpdateDto) => Promise<IGoodInventory | null>;

    /** Обработчик возобновления инвентаризации. */
    resumeHandler?: (inventory: IGoodInventory) => Promise<IGoodInventory | null>;

    /** Обработчик поиска товаров. */
    searchGoodsHandler?: (search: string, storeId: string) => Promise<IGood[]>;

    /** Обработчик загрузки товаров. */
    loadItemsHandler?: (
        inventory: IGoodInventory,
        options?: IGoodInventoryLoadItemsOptions,
    ) => Promise<ISelectedData<IGoodInventoryItem> | null>;

    /** Обработчик загрузки информации об инвентаризации. */
    loadReportHandler?: (inventory: IGoodInventory) => Promise<IGoodInventoryItemReportResult | null>;

    /** Обработчик заполнения таблицы нулевыми товарами. */
    populateItemsHandler?: (inventory: IGoodInventory) => Promise<boolean>;

    /** Обработчик добавления товара в инвентаризацию. */
    addGoodHandler?: (inventory: IGoodInventory, good: IGood, quantity: number) => Promise<IGoodInventoryItem | null>;

    /** Обработчик обновления количества товара в инвентаризации. */
    updateItemQuantityHandler?: (
        inventory: IGoodInventory,
        itemId: string,
        quantity: number,
    ) => Promise<IGoodInventoryItem | null>;

    /** Обработчик удаления товара из инвентаризации. */
    deleteItemHandler?: (inventory: IGoodInventory, itemId: string) => Promise<boolean>;
}

export enum GoodInventoryLoadItemsSort {
    Name,
    Date,
}

export interface IGoodInventoryLoadItemsOptions {
    limit?: number;
    offset?: number;
    diff?: boolean;
    sort?: GoodInventoryLoadItemsSort;
}

export class GoodInventoryModal extends Modal<IGoodInventoryModalContext, boolean> {
    private tbStore!: TextBox;
    private cbGood!: Select<IGood>;
    private chbDiff!: CheckBox;
    private pgPagination!: Pagination;
    private lbPagination!: Label;
    private btnSortDate!: Button;
    private btnSortName!: Button;
    private btngSort!: ButtonGroup;
    private tblTools!: Panel;
    private tblItemsTable!: Table<IGoodInventoryItem>;
    private taComment!: TextArea;

    private btnCancel!: Button;
    private btnComplete!: Button;
    private pnlFooter!: Panel;

    private context?: IGoodInventoryModalContext;

    private items: IGoodInventoryItem[] = [];
    private itemsTotal = 0;
    private itemsPage = 1;
    private itemsLimit = 20;
    private itemsSort = GoodInventoryLoadItemsSort.Date;

    private itemsSurplus = 0;
    private itemsShortage = 0;

    private delay = 700;

    public constructor(id = "good-inventory-modal") {
        super(id, "");
        this.initializeControls();
    }

    public get controls(): Control[] {
        return [
            this.tbStore,
            this.cbGood,
            this.chbDiff,
            this.tblTools,
            this.tblItemsTable,
            this.taComment,
            this.pnlFooter,
        ];
    }

    public async show(context: IGoodInventoryModalContext): Promise<boolean> {
        this.context = context;

        this.itemsSort = this.context.inventory.done
            ? GoodInventoryLoadItemsSort.Name
            : GoodInventoryLoadItemsSort.Date;

        this.initializeControls();

        await this.loadItems();
        this.populateControls(this.context.inventory);

        return super.show();
    }

    private initializeControls(): void {
        this.tbStore = new TextBox();
        this.tbStore.id = "good-inventory.store";
        this.tbStore.label = "Склад";
        this.tbStore.disabled = true;

        this.cbGood = new Select();
        this.cbGood.id = "good-inventory.good-search";
        this.cbGood.label = "Введите название товара";
        this.cbGood.items = [];
        this.cbGood.textField = e => e.info.name;
        this.cbGood.descriptionField = e => e.info.description;
        this.cbGood.search = search => this.searchGoods(search);
        this.cbGood.addChangedHandler(async (s, e) => {
            if (e.item) {
                await this.addItem(e.item, 1);
            }

            this.cbGood.reset();
        });

        this.chbDiff = new CheckBox();
        this.chbDiff.id = "good-inventory.good-diff";
        this.chbDiff.text = "Показать только отклонения";
        this.chbDiff.class = "mt-0.5";
        this.chbDiff.value = this.context?.inventory.done ?? false;
        this.chbDiff.addValueChangedHandler((s, e) => this.loadItems());

        this.pgPagination = new Pagination();
        this.pgPagination.class = "mr-0.5";
        this.pgPagination.limit = this.itemsLimit;
        this.pgPagination.addChangePageHandler((s, e) => this.changePage(e.page));

        this.lbPagination = new Label();
        this.lbPagination.class = "text-base mr-0.5";

        this.btnSortDate = new Button();
        this.btnSortDate.variant = this.itemsSort === GoodInventoryLoadItemsSort.Date ? "primary" : "outline-secondary";
        this.btnSortDate.text = "Добавл.";
        this.btnSortDate.addClickHandler(() => this.changeSort(GoodInventoryLoadItemsSort.Date));

        this.btnSortName = new Button();
        this.btnSortName.variant = this.itemsSort === GoodInventoryLoadItemsSort.Name ? "primary" : "outline-secondary";
        this.btnSortName.text = "Наимен.";
        this.btnSortName.addClickHandler(() => this.changeSort(GoodInventoryLoadItemsSort.Name));

        this.btngSort = new ButtonGroup();
        this.btngSort.size = "sm";
        this.btngSort.class = "mr-0.5";
        this.btngSort.addButtons([this.btnSortDate, this.btnSortName]);

        this.tblTools = new Panel();
        this.tblTools.class = "flex items-center justify-between mt-1";
        this.tblTools.addControls([this.btngSort, this.lbPagination, this.pgPagination]);

        this.tblItemsTable = new Table<IGoodInventoryItem>();
        this.tblItemsTable.id = "good-inventory.good-table";
        this.tblItemsTable.columns = this.getTableColumns(false);

        this.taComment = new TextArea();
        this.taComment.id = "good-inventory.comment";
        this.taComment.label = "Комментарий";
        this.taComment.addTextChangedHandler((s, e) => this.updateComment(e.text));

        this.btnCancel = new Button();
        this.btnCancel.id = "good-inventory.cancel";
        this.btnCancel.text = "Закрыть";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(() => this.clickCancel());

        this.btnComplete = new Button();
        this.btnComplete.id = "good-inventory.complete";
        this.btnComplete.text = "Завершить";
        this.btnComplete.addClickHandler(() => this.clickComplete());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-between mt-2";
        this.pnlFooter.addControl(this.btnComplete);
        this.pnlFooter.addControl(this.btnCancel);
    }

    private populateControls(inventory: IGoodInventory): void {
        this.title = `Инвентаризация #${inventory.number}`;

        this.tbStore.text = this.context?.store ? this.context.store.info.name : "";

        this.cbGood.visible = true;

        this.pgPagination.page = this.itemsPage;
        this.pgPagination.limit = this.itemsLimit;
        this.pgPagination.total = this.itemsTotal;

        this.tblItemsTable.items = this.getTableItems();
        this.tblItemsTable.columns = this.getTableColumns(inventory.done);
        this.tblItemsTable.footer = this.getTableFooter();

        this.taComment.text = inventory.comment ?? "";
        this.taComment.disabled = false;

        this.btnComplete.text = "Завершить";
        this.btnComplete.visible = true;

        if (inventory.done) {
            this.title += ` - ${Formatter.datetime(inventory.doneAt)}`;
            this.btnComplete.text = "Возобновить";
            this.taComment.disabled = true;
            this.cbGood.visible = false;
        }
    }

    private getTableItems(): IGoodInventoryItem[] {
        return this.items;
    }

    private getTableFooter(): TableFooter<IGoodInventoryItem>[] {
        const locale = this.context?.store?.info.locale ?? Locale.RU;

        return [
            {
                title: "Излишек:",
                cell: items => {
                    return Formatter.money(this.itemsSurplus, {
                        locale: locale,
                        grouping: true,
                        showNullFraction: false,
                    });
                },
            },
            {
                title: "Недостача:",
                cell: items => {
                    return Formatter.money(this.itemsShortage, {
                        locale: locale,
                        grouping: true,
                        showNullFraction: false,
                    });
                },
            },
        ];
    }

    private getTableColumns(readonly: boolean): TableColumn<IGoodInventoryItem>[] {
        const columns: TableColumn<IGoodInventoryItem>[] = [
            {
                title: "Наименование",
                width: 50,
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => {
                    const group = new Panel();
                    group.class = "flex";

                    const name = new Label();
                    name.class = "text-base text-overflow-dots";
                    name.style = "max-width: 25rem;";
                    name.text = item.name;

                    group.addControl(name);

                    if (item.good) {
                        const icon = new Icon();
                        icon.class = "cursor-pointer text-secondary hover:text-dark w-1 ml-0.75";
                        icon.icon = "ExternalLinkIcon";
                        icon.addClickHandler((s, e) => {
                            const routeData = router.resolve({ name: "stores", query: { id: item.good } });
                            window.open(routeData.href, "_blank");
                        });

                        group.addControl(icon);
                    }

                    return group;
                },
            },
            {
                title: "Факт",
                classHeader: "text-xs",
                width: 20,
                cell: item => {
                    if (readonly) {
                        const lbl = new Label();
                        lbl.id = Uuid.new();
                        lbl.class = "text-base";
                        lbl.text = item.inventoryQuantity.toString();
                        return lbl;
                    }

                    const number = new NumberBox();
                    number.id = Uuid.new();
                    number.style = "min-width: 9rem;";
                    number.min = 0;
                    number.max = Number.MAX_VALUE;
                    number.value = item.inventoryQuantity;
                    number.disabled = readonly;
                    number.addValueChangedHandler((s, e) => {
                        if (e.value === null) {
                            return;
                        }

                        this.updateItemQuantity(item.id, e.value);
                    });

                    return number;
                },
            },
            {
                title: "Учёт",
                classHeader: "text-xs",
                width: 20,
                cell: item => item.quantity.toString(),
            },
            {
                title: "Отклонение",
                classHeader: "text-xs",
                width: 20,
                cell: item => {
                    const delta = item.inventoryQuantity - item.quantity;
                    const color = delta > 0 ? "text-success" : delta < 0 ? "text-danger" : "";

                    const lbl = new Label();
                    lbl.id = Uuid.new();
                    lbl.class = `text-base ${color}`;
                    lbl.text = delta.toString();
                    return lbl;
                },
            },
            {
                title: "Цена",
                classHeader: "text-xs",
                width: 20,
                cell: item =>
                    Formatter.money(item.cost, {
                        locale: this.context?.store?.info.locale ?? Locale.RU,
                        grouping: true,
                        showNullFraction: false,
                    }),
            },
        ];

        if (!readonly) {
            columns.unshift({
                width: 1,
                cell: (item, index) => {
                    const iconDelete = new Icon();
                    iconDelete.icon = "Trash2Icon";
                    iconDelete.class = "cursor-pointer hover:text-danger";
                    iconDelete.addClickHandler(async () => await this.deleteItem(item, index));
                    return iconDelete;
                },
            });
        }

        return columns;
    }

    private searchGoods(search: string): void {
        if (!this.context || !this.context.searchGoodsHandler || !this.context?.store) {
            return;
        }

        const searchGoods = this.context.searchGoodsHandler;
        const storeId = this.context.store.id;
        search = search.trim();

        // начинать поиск от 2 символов
        if (search.length < 2) {
            this.cbGood.items = [];
            return;
        }

        DelayedOperation.invoke("inventory-search-goods", this.delay, async () => {
            this.cbGood.items = await searchGoods(search, storeId);
        });
    }

    private changeSort(sort: GoodInventoryLoadItemsSort): Promise<void> {
        if (this.itemsSort === sort) {
            return Promise.resolve();
        }

        this.itemsSort = sort;

        this.btnSortDate.variant = "outline-secondary";
        this.btnSortName.variant = "outline-secondary";

        if (sort === GoodInventoryLoadItemsSort.Date) {
            this.btnSortDate.variant = "primary";
        }

        if (sort === GoodInventoryLoadItemsSort.Name) {
            this.btnSortName.variant = "primary";
        }

        return this.loadItems(1);
    }

    private changePage(page: number): Promise<void> {
        return this.loadItems(page);
    }

    private updatePaginationText(): void {
        const offset = this.itemsLimit * (this.itemsPage - 1);
        const first = offset + 1;
        const last = Math.min(offset + this.itemsLimit, this.itemsTotal);
        this.lbPagination.text = `${first} - ${last} из ${this.itemsTotal}`;
    }

    private async loadItems(page?: number): Promise<void> {
        if (!this.context?.loadItemsHandler || !this.context?.loadReportHandler) {
            return;
        }

        if (page === undefined) {
            page = this.itemsSort === GoodInventoryLoadItemsSort.Date ? 1 : this.itemsPage;
        }

        const options: IGoodInventoryLoadItemsOptions = {
            limit: this.itemsLimit,
            offset: this.itemsLimit * (page - 1),
            diff: this.chbDiff.value,
            sort: this.itemsSort,
        };

        const result = await this.context.loadItemsHandler(this.context.inventory, options);

        if (!result) {
            return;
        }

        this.items = result.data;
        this.itemsTotal = result.total;
        this.itemsPage = page;
        this.updatePaginationText();

        const report = await this.context.loadReportHandler(this.context.inventory);

        if (!report) {
            return;
        }

        this.itemsSurplus = report.surplus;
        this.itemsShortage = report.shortage;

        //this.initializeControls();
        this.populateControls(this.context.inventory);
    }

    private async addItem(good: IGood, quantity: number): Promise<void> {
        if (!this.context?.addGoodHandler) {
            return;
        }

        const item = await this.context.addGoodHandler(this.context.inventory, good, quantity);

        if (!item) {
            return;
        }

        await this.loadItems();
    }

    private updateItemQuantity(itemId: string, quantity: number): void {
        if (!this.context?.updateItemQuantityHandler) {
            return;
        }

        const inventory = this.context.inventory;
        const updateItemQuantity = this.context.updateItemQuantityHandler;

        DelayedOperation.invoke(`inventory-update-item-quantity-${itemId}`, this.delay, async () => {
            const item = await updateItemQuantity(inventory, itemId, quantity);

            if (!item) {
                return;
            }

            await this.loadItems();
        });
    }

    private async deleteItem(item: IGoodInventoryItem, index: number): Promise<void> {
        if (!this.context?.deleteItemHandler) {
            return;
        }

        const confirm = await this.context.message.confirm(
            `Вы уверены, что хотите удалить товар "${item.name}"?`,
            "Удаление товара",
            { acceptText: "Удалить" },
        );

        if (!confirm) {
            return;
        }

        const deleted = await this.context.deleteItemHandler(this.context.inventory, item.id);

        if (!deleted) {
            return;
        }

        await this.loadItems();
    }

    private updateComment(comment: string): void {
        if (!this.context?.updateHandler || this.context.inventory.comment === comment) {
            return;
        }

        const inventory = this.context.inventory;
        const updateInventory = this.context.updateHandler;

        DelayedOperation.invoke("inventory-update-comment", this.delay, async () => {
            await updateInventory(inventory, { comment });
            inventory.comment = comment;
        });
    }

    private async clickCancel(): Promise<void> {
        this.hide(false);
    }

    private async clickComplete(): Promise<void> {
        if (!this.context || !this.tblItemsTable.items.length) {
            return;
        }

        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const inventory = this.context.inventory?.done ? await this.resume() : await this.complete();

        if (inventory) {
            this.context.inventory = inventory;
            this.populateControls(inventory);
        }
    }

    private async complete(): Promise<IGoodInventory | null> {
        if (!this.context?.updateHandler) {
            return null;
        }

        const dto: IGoodInventoryUpdateDto = {
            comment: this.taComment.text,
            done: true,
        };

        return await this.context.updateHandler(this.context.inventory, dto);
    }

    private async resume(): Promise<IGoodInventory | null> {
        if (!this.context?.resumeHandler || !this.context.inventory) {
            return null;
        }

        return await this.context.resumeHandler(this.context.inventory);
    }
}
