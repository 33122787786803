import { Component, Vue } from "vue-property-decorator";
import {
    ICompany,
    IGood,
    IGoodInventory,
    IGoodInventoryCreateDto,
    IGoodInventoryItem,
    IGoodInventoryItemCreateDto,
    IGoodInventoryItemReportResult,
    IGoodInventoryItemUpdateDto,
    IGoodInventoryUpdateDto,
    IReadQuery,
    ISelectedData,
    ISettingsTableUpdateDto,
    IStore,
    ITable,
    ITableFilter,
    ITableSort,
    IUser,
    PermissionRight,
    PermissionType,
    ReadQueryFilter,
    ReadQuerySort,
    TableType,
} from "@lib";
import AltTable, { TableApi } from "@/core/components/alt-table";
import { Button, Html, Icon } from "@core/components/alt-ui/controls";
import { ModalComponent } from "@core/components/alt-ui/modal/modal.component";
import { Modal2Component } from "@core/components/alt-ui/modal-2/modal-2.component";
import { GoodInventoryCreateModal } from "./modals/good-inventory-create.modal";
import {
    GoodInventoryLoadItemsSort,
    GoodInventoryModal,
    IGoodInventoryLoadItemsOptions,
} from "./modals/good-inventory.modal";
import { GoodInventoryV1Modal } from "./modals/good-inventory-v1.modal";
import { getDefaultTableColumns } from "./good-inventories-defaults";
import GoodInventoriesToolbar from "./good-inventories-toolbar.vue";
import { convertFilterBack } from "./good-inventories-filter-defaults";
import { AppException } from "@/core/exceptions";

@Component({
    name: "good-inventories",
    components: {
        AltTable,
        GoodInventoriesToolbar,
        ModalComponent,
        Modal2Component,
    },
})
export default class GoodInventories extends Vue {
    private GoodInventoryUseCase = this.$alt.system.usecase.createGoodInventoryUseCase();
    private GoodInventoryItemUseCase = this.$alt.system.usecase.createGoodInventoryItemUseCase();
    private SettingsTableUseCase = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private inventory: IGoodInventory[] = [];
    private inventoryTotal: number = 0;
    private stores: IStore[] = [];

    private goodInventoryCreateModal = new GoodInventoryCreateModal();
    private goodInventoryModal = new GoodInventoryModal();
    private goodInventoryV1Modal: GoodInventoryV1Modal;

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
    };

    constructor() {
        super();

        this.goodInventoryV1Modal = new GoodInventoryV1Modal("good-inventory-v1-modal");
        this.goodInventoryV1Modal.onCreate = this.create.bind(this);
        this.goodInventoryV1Modal.onUpdate = this.update.bind(this);
        this.goodInventoryV1Modal.onResume = this.resume.bind(this);
    }

    private get tableType(): TableType {
        return TableType.GoodInventory;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get inventoryData(): IGoodInventory[] {
        return this.inventory ? this.inventory : [];
    }

    private get inventoryDataTotal(): number {
        return this.inventoryTotal ? this.inventoryTotal : 0;
    }

    private get can(): any {
        const secure = this.$secure;
        const stores = this.stores;

        return {
            get read(): boolean {
                return true;
            },
            get update(): Function {
                return (goodInventory: IGoodInventory): boolean => {
                    return secure.check(PermissionType.Stores, goodInventory.store, PermissionRight.GoodsUpdate);
                };
            },
            get delete(): Function {
                return (goodInventory: IGoodInventory): boolean => {
                    return secure.check(PermissionType.Stores, goodInventory.store, PermissionRight.GoodsDelete);
                };
            },
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings?.columns ?? []);
    }

    private get limit(): number {
        return this.table.settings?.limit ?? TableApi.DefaultLimit;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings?.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings?.filter ?? [];
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.stores = await this.$info.getStores();
            await this.initData();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([this.selectTableSettings()]);

        const filter = {
            store: this.stores.map(e => e.id),
        };

        if (!this.table.settings) {
            this.table.settings = {};
        }

        this.table.settings.filter = convertFilterBack(filter);

        this.initHeader();
        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "good-inventories.header-title";
        hdrTitle.html = '<h2 class="m-0">Инвентаризации</h2>';

        const hdrRefresh = new Button();
        hdrRefresh.id = "good-inventories.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrTitle, hdrRefresh]);
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTableUseCase.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings?.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.inventory = [];
            this.inventoryTotal = 0;
        }
    }

    private async showModalCreate(): Promise<void> {
        const inventory = await this.goodInventoryCreateModal.show({
            userId: this.user.id,
            stores: this.stores,
            storeId: this.stores.length > 0 ? this.stores[0].id : "",
            createHandler: dto => this.$alt.longOperationFunc(() => this.create(dto)),
            populateHandler: inventory => this.$alt.longOperation(() => this.populate(inventory)),
        });

        if (inventory) {
            this.$alt.longOperation(() => this.selectData(this.skip, this.limit, this.sort, this.filter));
            await this.showModalUpdate(inventory);
        }
    }

    private async showModalUpdate(inventory: IGoodInventory): Promise<void> {
        if (!inventory.version) {
            // старая версия инвентаризации
            await this.goodInventoryV1Modal.show({
                userId: this.user.id,
                companyId: this.company.id,
                stores: this.stores,
                storeId: this.stores.length > 0 ? this.stores[0].id : "",
                goodInventory: inventory,
                message: this.$alt.message,
            });
            return;
        }

        await this.goodInventoryModal.show({
            userId: this.user.id,
            store: this.stores.find(s => s.id === inventory.store),
            message: this.$alt.message,
            inventory: inventory,
            searchGoodsHandler: (search, storeId) => this.searchGoods(search, storeId),
            updateHandler: (orig, dto) => this.update(orig.id, dto),
            resumeHandler: inventory => this.resume(inventory.id),
            loadItemsHandler: (inventory, options) => this.loadItems(inventory, options),
            loadReportHandler: inventory => this.loadReport(inventory),
            addGoodHandler: (inventory, good, quantity) => this.addItem(inventory, good, quantity),
            updateItemQuantityHandler: (inventory, itemId, quantity) =>
                this.updateItemQuantity(inventory, itemId, quantity),
            deleteItemHandler: (inventory, itemId) => this.deleteItem(inventory, itemId),
        });
    }

    private async confirmDelete(inventory: IGoodInventory): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить инвентаризацию: #${inventory.number}?`,
            "Удаление инвентаризации",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(inventory);
        }
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: IReadQuery = { limit, offset, sort, search, filter };
            const result = await this.GoodInventoryUseCase.select(this.company.id, query);

            this.inventory = result.data;
            this.inventoryTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить инвентаризации:\n${e.message}`);
        }
    }

    public async searchGoods(search: string, storeId: string): Promise<IGood[]> {
        try {
            const query: IReadQuery = {
                search,
                limit: 20,
                offset: 0,
            };

            const result = await this.$alt.system.usecase
                .createGoodUseCase()
                .selectForStore(this.company.id, storeId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private async create(dto: IGoodInventoryCreateDto): Promise<IGoodInventory | null> {
        try {
            const inventory = await this.GoodInventoryUseCase.create(this.company.id, dto);
            this.$alt.toast.success("Инвентаризация успешно создана.");
            return inventory;
        } catch (e: any) {
            throw new AppException(`Не удалось создать инвентаризацию:\n${e.message}`);
        }
    }

    private async update(id: string, dto: IGoodInventoryUpdateDto): Promise<IGoodInventory | null> {
        try {
            this.$alt.loader.show();

            const inventory = await this.GoodInventoryUseCase.update(this.company.id, id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно обновлена.");
            return inventory;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async resume(id: string): Promise<IGoodInventory | null> {
        try {
            this.$alt.loader.show();

            const inventory = await this.GoodInventoryUseCase.resume(this.company.id, id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно возобновлена.");
            return inventory;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(goodInventory: IGoodInventory): Promise<void> {
        try {
            this.$alt.loader.show();

            await this.GoodInventoryUseCase.delete(this.company.id, goodInventory.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно удалена.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async loadItems(
        inventory: IGoodInventory,
        options?: IGoodInventoryLoadItemsOptions,
    ): Promise<ISelectedData<IGoodInventoryItem> | null> {
        try {
            this.$alt.loader.show();

            const filter: ReadQueryFilter | undefined = options?.diff
                ? { inventoryQuantity: { ne: "$quantity" } }
                : undefined;

            const sort: ReadQuerySort[] =
                options?.sort === GoodInventoryLoadItemsSort.Name
                    ? [{ field: "name" }]
                    : [{ field: "updatedAt", order: "desc" }];

            const query: IReadQuery = {
                limit: options?.limit ?? 20,
                offset: options?.offset ?? 0,
                sort: sort,
                filter: filter,
            };
            return await this.GoodInventoryItemUseCase.select(this.company.id, inventory.id, query);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async loadReport(inventory: IGoodInventory): Promise<IGoodInventoryItemReportResult | null> {
        try {
            this.$alt.loader.show();
            return await this.GoodInventoryItemUseCase.report(this.company.id, inventory.id);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async populate(inventory: IGoodInventory): Promise<void> {
        try {
            await this.GoodInventoryItemUseCase.populateFromStore(this.company.id, inventory.id);
        } catch (e: any) {
            throw new AppException(`Не удалось заполнить инвентаризацию:\n${e.message}`);
        }
    }

    private async addItem(
        inventory: IGoodInventory,
        good: IGood,
        quantity: number,
    ): Promise<IGoodInventoryItem | null> {
        try {
            this.$alt.loader.show();

            const dto: IGoodInventoryItemCreateDto = {
                good: good.id,
                name: good.info.name,
                cost: good.info.cost,
                price: good.info.price,
                quantity: good.info.quantity,
                inventoryQuantity: quantity,
            };

            return await this.GoodInventoryItemUseCase.create(this.company.id, inventory.id, dto);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async updateItemQuantity(
        inventory: IGoodInventory,
        itemId: string,
        quantity: number,
    ): Promise<IGoodInventoryItem | null> {
        try {
            this.$alt.loader.show();

            const dto: IGoodInventoryItemUpdateDto = {
                inventoryQuantity: quantity,
            };

            return await this.GoodInventoryItemUseCase.update(this.company.id, inventory.id, itemId, dto);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteItem(inventory: IGoodInventory, itemId: string): Promise<boolean> {
        try {
            this.$alt.loader.show();

            await this.GoodInventoryItemUseCase.delete(this.company.id, inventory.id, itemId);
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
